
.student-admin{
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .student-admin-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
::v-deep .student-dialog{
  .el-dialog__header{
    padding: 16px 24px;
    background: #2DC079;
    span,i{
      font-size: 16px;
      line-height: 1;
      color: #fff;
    }
  }
  .el-dialog__body{
    padding:22px 24px;
    // 带表格的
    .el-form-item{
      margin-bottom: 20px;
    }
    .el-form-item__label{
      font-size: 16px;
      color: #666;
    }
    .dialog-footer{
      text-align: center;
      margin: 24px 0 18px;
    }
  }
}
.school-prompt{
  color: #999;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  span{
    line-height: 1;
    margin-top: 10px;
  }
}
.logo-src-box{
  width: 57px;
  height: 80px;
  box-sizing: border-box;
  position: relative;
  .src-box{
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 1px solid #EEEEEE;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  i{
    line-height: 1;
    font-size: 14px;
    color: #DCDCDC;
    position: absolute;
    right: -20px;
    top: -10px;
    cursor: pointer;
  }
}
.column-logo-box{
  display: flex;
  align-items: center;
  justify-content: center;
  .column-logo{
    width: 57px;
    height: 80px;
    overflow: hidden;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.logo-uploader{
  ::v-deep .el-upload{
    width: 57px;
    height: 80px;
    background: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo-uploader-icon{
      font-size: 32px;
      color: #A0A0A0;
    }
  }
}
